import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"
import IconMetaRow from "../../components/IconMetaRow"

export const pageQuery = graphql`
  query {
    rocklinImage: file(relativePath: { eq: "rocklin.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoWithRekeySetImage: file(relativePath: { eq: "leo-with-rekey-set.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function RocklinPage({ data }) {
  return (
    <MainLayout
      title="24 Hour Emergency Locksmith in Rocklin, CA | 916-995-0135"
      description="Full service mobile locksmith offering affordable locksmith services including lock installations, onsite lockout services, key duplication & more. Fast Response."
      offset
    >
      <ContactHeading background={data.rocklinImage.childImageSharp.fluid}>
        <h1 className="text-center">Rocklin Locksmith</h1>
        <h2 className="text-center font-weight-light">
          Leading Locksmith in Rocklin, CA
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">
          Professional, Dependable, & Affordable Locksmith
        </h2>
        <p className="indent mb-0">
          Swift Locksmith is a licensed and trusted family business that's been
          providing quality residential, commercial and automotive locksmith
          services for residents of Rocklin, CA and the neighboring areas. Our
          clients always come first, so whatever locksmith services you are
          looking for, we're available at all times of the day and night for
          you. Our staff has been in the locksmith service industry for a very
          long time and along with their understanding and expertise, we
          guarantee that you're in safe hands. We provide exceptional
          residential, automotive and commercial locksmith services which vary
          from a standard lockout service to setting up a high protection system
          for your office or home. Swift locksmith is Rocklin's trusted
          locksmith so give us a ring today for your locksmith needs.
        </p>
      </Container>
      <Container as="section">
        <h2>Rocklin's Residential Locksmith</h2>
        <Row className="mb-3">
          <Col xs={12} md={6} lg={7} xl={8}>
            <p className="mb-md-0">
              For all of our residential locksmithing services, we are pleased
              to make a complete assessment and estimate to be able to provide
              the best and complete security for your home. Backed by many years
              of business knowledge, the specialized expertise of ours and also
              innovative equipment enable us to impress our clients by exceeding
              their every hope while sticking to their time and spending budget
              restrictions. Whether you need to rekey your home or repair your
              safe locks, our technicians have the knowledge to do the task
              properly.
            </p>
          </Col>
          <Col xs={12} md={6} lg={5} xl={4} className="col-sm-pad">
            <Img
              fluid={data.leoWithRekeySetImage.childImageSharp.fluid}
              alt="leo-with-rekey-set"
            />
          </Col>
        </Row>
        <h2>Rocklin's Automotive Locksmith</h2>
        <Row className="mb-3">
          <Col xs={12} md={6} lg={7} xl={8}>
            <p className="mb-md-0">
              Did you lose your keys? Are you locked out of your vehicle? Don't
              worry, we got you covered. Our licensed technicians are able to
              unlock your car door, duplicate lost keys or even extract a broken
              key from your car quickly and in a professional manner. Our team
              is proud to offer quick, affordable and dependable lockout service
              to clients in need. Please call us today if you are looking for
              automotive emergency services. We will promptly send a qualified
              technician to assist you with your car and to ensure the safety of
              you and your vehicle.
            </p>
          </Col>
          <Col xs={12} md={6} lg={5} xl={4} className="col-sm-pad">
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
            />
          </Col>
        </Row>
        <h2>Rocklin's Commercial Locksmith</h2>
        <Row>
          <Col xs={12} md={6} lg={7} xl={8}>
            <p className="mb-md-0">
              As one the the top commercial locksmiths in Rocklin, CA, it's our
              mission to keep your company properly operating without any
              issues, whether that suggests rekeying locks or even putting in
              keyless door locks. Our team's focus is to provide exemplary
              customer service, supported by the industry's largest locksmithing
              standards. We help business personnel every day throughout the
              city of Rocklin with their locksmith and security needs. Your
              company's equipment, assets, and staff must always be in safe
              hands, meaning secure locks are a necessity.
            </p>
          </Col>
          <Col xs={12} md={6} lg={5} xl={4} className="col-sm-pad">
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-van-in-city"
            />
          </Col>
        </Row>
      </Container>
      <section>
        <div className="background-content bg-dark text-primary">
          <Container>
            <IconMetaRow textClass="text-white lead" />
          </Container>
        </div>
      </section>
      <Container as="section" className="mb-5">
        <h2>Choosing Swift Locksmith in Rocklin, CA</h2>
        <p className="indent">
          When you choose us, you're putting expertise to work where it counts
          most: the security for yourself and the protection for your business
          or home. With more than 10 years of expertise in the locksmith
          business, our skilled locksmiths provide complete automotive,
          residential, and commercial locksmith services for Rocklin residents
          and the nearby areas. We have all of the resources necessary to
          resolve any and every locksmith need that you have.
        </p>
        <p className="indent mb-0">
          Our team of locksmith technicians are always available when you need
          us most because we are a 24/7 mobile locksmith company. It is crucial
          to find a reliable locksmith who you can depend on anytime. We respond
          to our customers' calls urgently, resolve any locksmith needs
          promptly, and provide competitive pricing so you don't waste your time
          and money with us.
        </p>
      </Container>
    </MainLayout>
  )
}

export default RocklinPage
