import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "react-bootstrap"
import { CertificateIcon, ToolsIcon, TwentyFourSevenIcon } from "../../icons"

function IconMetaRow({ textClass }) {
  let paragraphClass = "mb-0 mt-3 font-weight-bold text-center"
  if (textClass) {
    paragraphClass += ` ${textClass}`
  } else {
    paragraphClass += " text-dark"
  }
  return (
    <Row>
      <Col
        xs={12}
        md={4}
        className="d-flex flex-column justify-content-center align-items-center mb-5 mb-md-0"
      >
        <TwentyFourSevenIcon style={{ height: 110, width: "auto" }} />
        <p className={paragraphClass}>24/7 EMERGENCY SERVICES</p>
      </Col>
      <Col
        xs={12}
        md={4}
        className="d-flex flex-column justify-content-center align-items-center mb-5 mb-md-0"
      >
        <CertificateIcon style={{ height: 110, width: "auto" }} />
        <p className={paragraphClass}>LICENSED & INSURED</p>
      </Col>
      <Col
        xs={12}
        md={4}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <ToolsIcon style={{ height: 110, width: "auto" }} />
        <p className={paragraphClass}>PROFESSIONAL SERVICES</p>
      </Col>
    </Row>
  )
}

IconMetaRow.propTypes = {
  textClass: PropTypes.string,
}

export default IconMetaRow
